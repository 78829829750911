import React, { useState } from "react";
import "./CreateForm.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface FormData {
  fieldName: string;
  fieldType: string;
  isMandatory: string;
}

const CreateForm = () => {
  const [formData, setFormData] = useState<FormData>({
    fieldName: "",
    fieldType: "",
    isMandatory: "",
  });

  const [submissions, setSubmissions] = useState<FormData[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<string>("create");
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editIndex !== null) {
      const updatedSubmissions = submissions.map((item, index) =>
        index === editIndex ? formData : item
      );
      setSubmissions(updatedSubmissions);
      setEditIndex(null);
    } else {
      setSubmissions((prevSubmissions) => [...prevSubmissions, formData]);
    }
    setFormData({
      fieldName: "",
      fieldType: "",
      isMandatory: "",
    });
  };

  const handleCardInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { id, value } = e.target;
    const updatedSubmissions = submissions.map((item, idx) =>
      idx === index ? { ...item, [id]: value } : item
    );
    setSubmissions(updatedSubmissions);
  };

  const handleEdit = (index: number) => {
    setEditIndex(index);
  };

  const handleUpdate = () => {
    setEditIndex(null);
  };

  const handleDelete = (index: number) => {
    setSubmissions(submissions.filter((_, i) => i !== index));
  };

  return (
    <div className="createform">
      <div className="form-tabs">
        <div
          style={{
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
            backgroundColor: activeTab === "create" ? "green" : "white",
            color: activeTab === "create" ? "white" : "green",
            border:
              activeTab === "create" ? "1px solid green" : "1px solid green",
          }}
          onClick={() => setActiveTab("create")}
        >
          Create Form
        </div>
        <div
          style={{
            borderBottomRightRadius: "10px",
            borderTopRightRadius: "10px",
            backgroundColor: activeTab === "details" ? "green" : "white",
            color: activeTab === "details" ? "white" : "green",
            border:
              activeTab === "details" ? "1px solid green" : "1px solid green",
          }}
          onClick={() => setActiveTab("details")}
        >
          Field Details
        </div>
      </div>
      {activeTab === "create" && (
        <Card
          style={{
            background: "#fff",
            borderRadius: "10px",
            marginBottom: "3%",
          }}
          className="field-card"
        >
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <div className="form-content">
                <div className="input-row">
                  <div>
                    <label htmlFor="fieldName">
                      Field Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      style={{
                        height: "54px",
                        borderRadius: "5px",
                      }}
                      type="text"
                      id="fieldName"
                      placeholder="Field Name"
                      required
                      value={formData.fieldName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="fieldType">
                      Field Type<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      style={{
                        height: "54px",
                      }}
                      id="fieldType"
                      required
                      value={formData.fieldType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Field Type</option>
                      <option value="Text">Text</option>
                      <option value="Number">Number</option>
                      <option value="Decimal">Decimal</option>
                      <option value="Email Id">Email Id</option>
                      <option value="Mobile Number">Mobile Number</option>
                      <option value="Date">Date</option>
                      <option value="Dropdown">Dropdown</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="isMandatory">
                      Mandatory or Not<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      style={{
                        height: "54px",
                      }}
                      id="isMandatory"
                      required
                      value={formData.isMandatory}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="Mandatory">Mandatory</option>
                      <option value="Not Mandatory">Not Mandatory</option>
                    </select>
                  </div>
                </div>
                <div
                  className="input-row"
                  style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>{" "}
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      )}
      {activeTab === "details" &&
        submissions.map((submission, index) => (
          <Card
            key={index}
            style={{
              background: "#fff",
              borderRadius: "10px",
              marginBottom: "3%",
            }}
            className="field-card"
          >
            <Card.Body>
              <div className="form-content">
                <div className="input-row">
                  <div>
                    <label>Field Name</label>
                    <Form.Control
                      style={{
                        height: "54px",
                        borderRadius: "5px",
                      }}
                      type="text"
                      id="fieldName"
                      value={submission.fieldName}
                      readOnly={editIndex !== index}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCardInputChange(e, index)
                      }
                    />
                  </div>
                  <div>
                    <label>Field Type</label>
                    <Form.Control
                      style={{
                        height: "54px",
                      }}
                      as="select"
                      id="fieldType"
                      value={submission.fieldType}
                      readOnly={editIndex !== index}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCardInputChange(e, index)
                      }
                    >
                      <option value="Text">Text</option>
                      <option value="Number">Number</option>
                      <option value="Decimal">Decimal</option>
                      <option value="Email Id">Email Id</option>
                      <option value="Mobile Number">Mobile Number</option>
                      <option value="Date">Date</option>
                      <option value="Dropdown">Dropdown</option>
                    </Form.Control>
                  </div>
                  <div>
                    <label>Mandatory or Not</label>
                    <Form.Control
                      style={{
                        height: "54px",
                      }}
                      as="select"
                      id="isMandatory"
                      value={submission.isMandatory}
                      readOnly={editIndex !== index}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCardInputChange(e, index)
                      }
                    >
                      <option value="Mandatory">Mandatory</option>
                      <option value="Not Mandatory">Not Mandatory</option>
                    </Form.Control>
                  </div>
                </div>
                <div
                  className="input-row"
                  style={{
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {editIndex === index ? (
                    <Button
                      variant="success"
                      style={{ margin: "0% 2%" }}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      style={{ margin: "0% 2%" }}
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </Button>
                  )}
                  <div
                    style={{
                      width: "4%",
                      backgroundColor: isVisible === true ? "green" : "red",
                      border:
                        isVisible === true
                          ? "1px solid green"
                          : "1px solid red",
                    }}
                    className="visible"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {isVisible === true ? <i style={{ color: "#fff" }} className="bi bi-eye"></i> : <i style={{ color: "#fff" }} className="bi bi-eye-slash"></i>}
                  </div>
                  <Button
                    variant="danger"
                    style={{ margin: "0% 2%" }}
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default CreateForm;
