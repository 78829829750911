import React from 'react';
import './CircularProgress.css';
import CenterImage from '../../assets/dashboard/bar-chart.svg'; // Adjust the path as needed

interface CircularProgressProps {
  percentage: number;
  innerPercentage: number;
  outerColor: string;
  innerColor: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage, innerPercentage, outerColor, innerColor }) => {
  const outerCircleRadius = 25;
  const outerCircumference = 2 * Math.PI * outerCircleRadius;
  const innerCircleRadius = outerCircleRadius - 8;
  const innerCircumference = 2 * Math.PI * innerCircleRadius;
  const strokeDasharrayOuter = `${(percentage / 100) * outerCircumference}, ${outerCircumference}`;
  const strokeDasharrayInner = `${(innerPercentage / 100) * innerCircumference}, ${innerCircumference}`;

  const getCoordinatesForAngle = (percentage: number, radius: number) => {
    const innerAngle = (percentage / 100) * 360 - 95;
    const angle = (percentage / 100) * 360 - 110; // Adjusting the starting angle to top
    const textAngle = 150;
    const outerTextAngle = 258;
    const radians = (angle * Math.PI) / 180;
    const outerradians = (innerAngle * Math.PI) / 180;
    return {
      x: 30 + radius * Math.cos(radians),
      xouter: 30.5 + radius * Math.cos(outerradians),
      y: 35 + radius * Math.sin(radians),
      youter: 31.5 + radius * Math.sin(outerradians),
      angle: angle,
      textAngle: textAngle,
      outerTextAngle: outerTextAngle
    };
  };

  const outerCoordinates = getCoordinatesForAngle(percentage, outerCircleRadius - 2); // Move slightly inside
  const innerCoordinates = getCoordinatesForAngle(innerPercentage, innerCircleRadius - 2); // Move slightly inside

  return (
    <div className="progress-circle">
      <svg viewBox="0 0 64 64" className="circular-chart">
        {/* Outer Circle Background */}
        <path
          className="circle-bg"
          d={`M32 7
             a ${outerCircleRadius} ${outerCircleRadius} 0 0 1 0 ${2 * outerCircleRadius}
             a ${outerCircleRadius} ${outerCircleRadius} 0 0 1 0 -${2 * outerCircleRadius}`}
        />
        {/* Outer Circle */}
        <path
          className="circle"
          stroke={outerColor}
          strokeDasharray={strokeDasharrayOuter}
          d={`M32 7
             a ${outerCircleRadius} ${outerCircleRadius} 0 0 1 0 ${2 * outerCircleRadius}
             a ${outerCircleRadius} ${outerCircleRadius} 0 0 1 0 -${2 * outerCircleRadius}`}
        />
        
        {/* Inner Circle Background */}
        <path
          className="circle-bg"
          d={`M32 15
             a ${innerCircleRadius} ${innerCircleRadius} 0 0 1 0 ${2 * innerCircleRadius}
             a ${innerCircleRadius} ${innerCircleRadius} 0 0 1 0 -${2 * innerCircleRadius}`}
        />
        {/* Inner Circle */}
        <path
          className="circle"
          stroke={innerColor}
          strokeDasharray={strokeDasharrayInner}
          d={`M32 15
             a ${innerCircleRadius} ${innerCircleRadius} 0 0 1 0 ${2 * innerCircleRadius}
             a ${innerCircleRadius} ${innerCircleRadius} 0 0 1 0 -${2 * innerCircleRadius}`}
        />
        
        {/* Center Image */}
        <image href={CenterImage} x="21" y="21" height="22" width="22" />

        {/* Outer Percentage Text */}
        <text
          x={outerCoordinates.xouter}
          y={outerCoordinates.youter}
          fill="#fff"
          fontSize="3.2"
          textAnchor="middle"
          alignmentBaseline="middle"
          transform={`rotate(${outerCoordinates.outerTextAngle}, ${outerCoordinates.xouter}, ${outerCoordinates.youter})`}
        >
          {percentage}%
        </text>

        {/* Inner Percentage Text */}
        <text
          x={innerCoordinates.x}
          y={innerCoordinates.y }
          fill="#fff"
          fontSize="3.2"
          textAnchor="middle"
          alignmentBaseline="middle"
          transform={`rotate(${innerCoordinates.textAngle}, ${innerCoordinates.x}, ${innerCoordinates.y})`}
        >
          {innerPercentage}%
        </text>
      </svg>
    </div>
  );
};

export default CircularProgress;
