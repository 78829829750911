// Sidebar.tsx
import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Dashboard from "../../assets/sidebar/dashboard.svg";
import DashboardSelected from "../../assets/sidebar/dashboard-select.svg";
import Deals from "../../assets/sidebar/deals.svg";
import DealsSelected from "../../assets/sidebar/deals-selected.svg";
import Dropdown from "../../assets/sidebar/dropdown.svg";
import Goto from "../../assets/sidebar/goto.svg";
import Leads from "../../assets/sidebar/leads.svg";
import LeadsSelected from "../../assets/sidebar/leads-selected.svg";
import Notifications from "../../assets/sidebar/notifications.svg";
import Payments from "../../assets/sidebar/payments.svg";
import Renewals from "../../assets/sidebar/renewals.svg";
import PaymentsSelected from "../../assets/sidebar/payments-selected.svg";
import RenewalsSelected from "../../assets/sidebar/renewal-selected.svg";
import Setting from "../../assets/sidebar/settings.svg";
import user from "../../assets/sidebar/sample-user.svg";
import Logo from "../../assets/sidebar/logo.svg";
import Open from "../../assets/sidebar/open.svg";
import closeIcon from "../../assets/dashboard/close.svg";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState<string>(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    return storedMenu ? storedMenu : "Dashboard";
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
    console.log("activeMenu", activeMenu);
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            {isOpen ? (
              <Link
                to="/layout/dashboard"
                style={{ width: "80px", height: "80px" }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "100%" }}
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            ) : (
              <Link
                to="/layout/dashboard"
                style={{ width: "80px", height: "80px" }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "100%" }}
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            )}
          </div>
          {isOpen && <div className="logo-title">ZERA LEAD</div>}
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isOpen ? (
              <img src={Open} alt="open" className="arrow-icon" />
            ) : (
              <img src={Open} alt="Close" className="arrow-icon" />
            )}
          </div>
        </div>
      </div>

      <ul className="sidebar-menu">
        <div>
          {/* Dashboard */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Dashboard"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Dashboard" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Dashboard")}
            >
              <img
                src={activeMenu === "Dashboard" ? DashboardSelected : Dashboard}
                alt="dashboard"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span>Dashboard</span>}
                {isOpen && activeMenu !== "Dashboard" && (
                  <img src={Goto} alt="go-to" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Leads */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Leads"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Leads" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Leads")}
            >
              <img
                src={activeMenu === "Leads" ? LeadsSelected : Leads}
                alt="Leads"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span>Leads</span>}
                {isOpen && activeMenu !== "Leads" && (
                  <img src={Goto} alt="Leads" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Deals */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Deals"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Deals" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Deals")}
            >
              <img
                src={activeMenu === "Deals" ? DealsSelected : Deals}
                alt="Deals"
              />
              <div className="sidebar-title">
                {isOpen && <span>Deals</span>}
                {isOpen && activeMenu !== "Deals" && (
                  <img src={Goto} alt="go-to" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Renewals */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Renewals"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Renewals" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Renewals")}
            >
              <img
                src={activeMenu === "Renewals" ? RenewalsSelected : Renewals}
                alt="Renewals"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span>Renewals</span>}
                {isOpen && activeMenu !== "Renewals" && (
                  <img src={Goto} alt="Goto" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
          {/* Payments */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Payments"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Payments" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Payments")}
            >
              <img
                src={activeMenu === "Payments" ? PaymentsSelected : Payments}
                alt="Payments"
              />{" "}
              <div className="sidebar-title">
                {isOpen && <span>Payments</span>}
                {isOpen && activeMenu !== "Payments" && (
                  <img src={Goto} alt="Goto" className="right-arrow" />
                )}
              </div>
            </NavLink>
          </li>
        </div>

        <div className="sidebar-menu">
          {/* Notifications */}
          <li style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <div
              className="menu-button"
              style={{
                background:
                  activeMenu === "Notifications"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Notifications" ? "#fff" : "black",
                padding: "8px",
              }}
              onClick={() => handleMenuClick("Notifications")}
            >
              <img src={Notifications} alt="dashboard" />{" "}
              <div className="sidebar-title">
                {isOpen && <span>Notifications</span>}
                {isOpen && (
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      background: "rgba(249, 249, 253, 1)",
                      color: "rgba(87, 89, 91, 1)",
                      fontSize: "12px",
                      padding: "4px 9px",
                      borderRadius: "50%",
                    }}
                  >
                    8
                  </div>
                )}
              </div>
            </div>
          </li>
          {/* Settings */}
          <li style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <div
              className="menu-button"
              style={{
                background:
                  activeMenu === "Settings"
                    ? "linear-gradient(180deg, #0E8C43 0%, #005323 100%)"
                    : "rgba(245, 245, 245, 1)",
                color: activeMenu === "Settings" ? "#fff" : "black",
                padding: "8px",
              }}
              onClick={() => handleMenuClick("Settings")}
            >
              <img src={Setting} alt="Setting" />{" "}
              {isOpen && <span>Settings</span>}
            </div>
          </li>
          {/* user */}
          <li>
            <NavLink
              to="/layout/dashboard"
              className="menu-button"
              style={{
                backgroundColor:
                  activeMenu === "user" ? "#642d97" : "rgba(245, 245, 245, 1)",
                color: activeMenu === "user" ? "#fff" : "black",
                padding: "0px",
              }}
            >
              <img src={user} alt="user" style={{ width: "42px" }} />{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {isOpen && (
                  <div style={{ marginLeft: "5%" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        fontWeight: "500",
                        marginLeft: "0%",
                        color: "rgba(0, 0, 0, 1)"
                      }}
                    >
                      Prem
                    </span>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        textAlign: "left",
                        color: "rgba(117, 117, 117, 1)",
                      }}
                    >
                      Project Manager
                    </div>
                  </div>
                )}
                <img
                  src={Dropdown}
                  alt="dropdown"
                  style={{ width: "24px", height: "24px" }}
                />{" "}
              </div>
            </NavLink>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
