import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/zeraleads.svg";
import "./login.css";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email: any) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailBlur = () => {
    if (email.trim() === "") {
      setEmailError("*Email is required*");
    } else if (!validateEmail(email)) {
      setEmailError("*Please enter a valid email address.*");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordBlur = () => {
    if (password.trim() === "") {
      setPasswordError("*Password is required*");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault(); // Prevents the default form submission behavior
    login();
    setTimeout(() => {
      navigate("/layout/dashboard");
    }, 1000);
  };

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="wrapper">
      <Container style={{ width: "30rem" }}>
        <Card className="login-container">
          <Card.Img
            style={{ width: "80px", height: "80px" }}
            variant="top"
            src={logo}
          />
          <Card.Body style={{ width: "100%" }}>
            <Card.Title
              style={{
                textAlign: "center",
                color: "#5b626b",
                margin: "4% 0% 7%",
              }}
            >
              Sign In
            </Card.Title>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                className="input"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                onBlur={handleEmailBlur}
                style={{ borderColor: emailError ? "red" : "" }}
              />
              {emailError && <div className="error-message">{emailError}</div>}
              <div className="password-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input"
                  placeholder="Password"
                  value={password}
                  required
                  onBlur={handlePasswordBlur}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    marginTop: "4%",
                    width: "96%",
                    borderColor: passwordError ? "red" : "",
                  }}
                />
                <span className="toggle-password" onClick={togglePassword}>
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </span>
              </div>
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
              <Button
                variant="primary"
                size="lg"
                style={{
                  height: "36px",
                  borderRadius: "5px",
                  background: "#EA4661",
                  color: "#FEF6F7",
                  border: ".5px solid #FEF6F7",
                  fontSize: "16px",
                  cursor: "pointer",
                  marginTop: "5%",
                }}
                type="submit"
              >
                Sign In
              </Button>
            </form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Login;
