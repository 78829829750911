import React, { createContext, useContext, useEffect, useState } from "react";
import "./Header.css";
import AddNew from "../../assets/header/addNew.png";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const openModal = () => {
    navigate("/layout/createform");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("searchQuery", searchQuery);
  }, [searchQuery]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div className="header">
      <div className="left-side">Welcome, Mr.Prem!</div>
      <div className="right-side">
        <div className="search-field">
          <button className="add-button" onClick={openModal}>
            <img src={AddNew} alt="plus" />
          </button>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {searchQuery.trim() !== "" && (
            <div className="additional-content">
              {searchResults.length > 0 ? (
                <div className="search-results">
                  {/* {searchResults.map((result, index) => (
                  ))} */}
                </div>
              ) : (
                <div className="card-search">
                  <div className="card-body-search">
                    <h5
                      className="card-title-search"
                      style={{ color: "black" }}
                    >
                      No results available
                    </h5>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
