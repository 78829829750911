import React, { useEffect, useRef, useState } from "react";
import Increase from "../../assets/dashboard/increase.svg";
import Decrease from "../../assets/dashboard/decrease.svg";
import ViewLead from "../../assets/dashboard/viewLead.svg";
import CallsInfo from "../../assets/dashboard/calls-info.svg";
import MeetingsInfo from "../../assets/dashboard/meeting-info.svg";
import TotalSubscribers from "../../assets/dashboard/totalSubscribers.svg";
import HotLeads from "../../assets/dashboard/hotLeads.svg";
import WarmLeads from "../../assets/dashboard/warmLeads.svg";
import ColdLeads from "../../assets/dashboard/coldLeads.svg";
import Meeting from "../../assets/dashboard/meeting.svg";
import Calls from "../../assets/dashboard/calls.svg";
import PendingDeals from "../../assets/dashboard/pendingDeals.svg";
import TodayDeals from "../../assets/dashboard/todayDeals.svg";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import CircularProgress from "../layout/CircularProgress";
import "./Dashboard.css";

interface BarChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderRadius: number;
    borderColor: string;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
  }[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard: React.FC = () => {
  const [barChartData, setBarChartData] = useState<BarChartData>({
    labels: [],
    datasets: [],
  });
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");

  useEffect(() => {
    const barchartdata = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Overview",
          data: [30, 20, 50, 40, 60, 70, 45, 55, 65, 75, 80, 90],
          backgroundColor: "rgba(195, 255, 228, 1)",
          borderRadius: 9,
          borderColor: "rgba(195, 255, 228, 1)",
          hoverBackgroundColor: "rgba(13, 137, 65, 1)",
          hoverBorderColor: "rgba(13, 137, 65, 1)",
          borderSkipped: false,
        },
      ],
    };
    setBarChartData(barchartdata);
  }, []);

  const barchartoptions = {
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        display: false,
        grid: { display: false },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context: any) => "",
          labelColor: (context: any) => ({
            borderColor: "rgba(13, 137, 65, 1)",
            backgroundColor: "rgba(13, 137, 65, 1)",
          }),
          label: (context: any) => {
            const value = context.raw;
            return `${value}%`;
          },
        },
      },
      legend: {
        display: false
      }
    },
  };

  return (
    <div className="dashboard">
      <div className="card-container">
        <>
          {/* Total Leads */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Total Leads</span>
                <img
                  src={TotalSubscribers}
                  alt="Total Leads"
                  className="leads-icon"
                />
              </div>
              <h1 className="card-info">150</h1>
              <span className="percentage-container">
                <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                <span className="percentage">8.5%</span>Up from Last year
              </span>
            </a>
          </div>
          {/* Hot Leads */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Hot Leads</span>
                <img src={HotLeads} alt="Hot leads" className="leads-icon" />
              </div>
              <h1 className="card-info">45</h1>
              <span className="percentage-container">
                <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                <span className="percentage">1.3%</span> Up from Last year
              </span>{" "}
            </a>
          </div>
          {/* Warm Leads */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Warm Leads</span>
                <img src={WarmLeads} alt="Warm Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">254</h1>
              <span className="percentage-container">
                <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                <span className="percentage">8.5%</span> Up from Last year
              </span>{" "}
            </a>
          </div>
          {/* Cold Leads */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Cold Leads</span>
                <img src={ColdLeads} alt="Cold Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">5</h1>
              <span className="percentage-container">
                <img src={Increase} alt="Up Arrow" className="arrow-icon" />
                <span className="percentage">1.3%</span> Up from last year
              </span>{" "}
            </a>
          </div>
          {/* Meeting */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Meetings</span>
                <img src={Meeting} alt="Meetings" className="leads-icon" />
              </div>
              <h1 className="card-info">08</h1>
            </a>
          </div>
          {/* Calls */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Calls</span>
                <img src={Calls} alt="Calls" className="leads-icon" />
              </div>
              <h1 className="card-info">17</h1>
            </a>
          </div>
          {/* Today deals */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Today Deals</span>
                <img
                  src={TodayDeals}
                  alt="Today Deals"
                  className="leads-icon"
                />
              </div>
              <h1 className="card-info">01</h1>
            </a>
          </div>
          {/* Pending deals */}
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Pending Deals</span>
                <img
                  src={PendingDeals}
                  alt="Pending Deals"
                  className="leads-icon"
                />
              </div>
              <h1 className="card-info">05</h1>
              <span className="percentage-container"></span>{" "}
            </a>
          </div>
        </>
      </div>
      <div className="container">
        <div className="card-chart" style={{ padding: "2%", minWidth: "60%" }}>
          <h4 style={{ fontWeight: "600", fontSize: "22px" }}>Overview</h4>
          <p>Converted Leads</p>
          <>
            <div className="select-container">
              <select
                style={{
                  height: "35px",
                  width: "100px",
                  paddingLeft: "7px",
                  marginTop: "15%",
                }}
                value={dataPeriod}
                onChange={(e) =>
                  setDataPeriod(e.target.value as "yearly" | "monthly")
                }
              >
                <option value="monthly">Monthly</option>
              </select>
            </div>
            <div className="overview-chart">
              <div className="overview-card" style={{ height: "318px" }}>
                <div className="overview-card-body">
                  <Bar data={barChartData} options={barchartoptions} />
                </div>
              </div>
            </div>
          </>
        </div>
        <div
          className="card-chart"
          style={{ padding: "2%", marginRight: "0%", minWidth: "38%" }}
        >
          <h4 style={{ fontWeight: "600", fontSize: "22px" }}>Follow ups</h4>
          <p>Calls & Meetings</p>
          <>
            <div className="bar-chart">
              {" "}
              <CircularProgress
                percentage={75}
                innerPercentage={45}
                outerColor="rgba(0, 146, 6, 1)"
                innerColor="rgba(2, 93, 40, 1)"
              />{" "}
            </div>
          </>
          <div className="chart-info">
            <div>
              <img style={{ marginTop: "-3px" }} src={CallsInfo} alt="Calls-info" />
              <span style={{ color: "rgba(0, 146, 6, 1)", marginLeft: "4%", fontWeight: "400" }}>
                Calls
              </span>
            </div>
            <div>
              <img style={{ marginTop: "-3px" }} src={MeetingsInfo} alt="Meeting-info" />
              <span style={{ color: "rgb(2 119 51)", marginLeft: "4%", fontWeight: "400" }}>
                Meetings
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-table">
        <div className="table-container">
          <div className="table-header">
            <p>Follow up summary</p>
            <div className="table-search">
              <div className="search-field1">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input"
                />
              </div>
              <div className="dropdown-container">
                <select>
                  <option value="">Overdue</option>
                </select>
              </div>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Lead Source</th>
                <th>Company Name</th>
                <th>Lead Status</th>
                <th>Mobile Number</th>
                <th>E-Mail</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>22/05/2024</td>
                <td>Ajaykumar</td>
                <td>India Mart</td>
                <td>Savon solutions</td>
                <td style={{ color: "rgba(221, 54, 2, 1)" }}>Warm</td>
                <td>9043567896</td>
                <td>test@gmail.com</td>
                <td>
                  <img src={ViewLead} alt="View lead" />
                </td>
              </tr>
              <tr>
                <td>22/05/2024</td>
                <td>Ajaykumar</td>
                <td>Facebook</td>
                <td>Savon solutions</td>
                <td style={{ color: "rgba(0, 72, 180, 1)" }}>Cold</td>
                <td>9043567896</td>
                <td>test@gmail.com</td>
                <td>
                  <img src={ViewLead} alt="View lead" />
                </td>
              </tr>
              <tr>
                <td>22/05/2024</td>
                <td>Ajaykumar</td>
                <td>Referral</td>
                <td>Savon solutions</td>
                <td style={{ color: "rgba(216, 0, 0, 1)" }}>Hot</td>
                <td>9043567896</td>
                <td>test@gmail.com</td>
                <td>
                  <img src={ViewLead} alt="View lead" />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
