import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import Layout from "./Layout";
import Login from "./component/login/login";
import Dashboard from "./component/page/Dashboard";
import CreateForm from "./component/page/CreateForm";
import "./App.css";
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/layout/*" element={<PrivateRoute element={<Layout />} />}>
            <Route index path="dashboard" element={<Dashboard />} />
            <Route index path="createform" element={<CreateForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
